// File: hooks/useForm.ts
import { useState } from 'react';
import { z } from 'zod';

type FormFieldValue = string | number | boolean;

export default function useForm<TSchema extends z.ZodObject<z.ZodRawShape, 'strip', z.ZodTypeAny>>(
  schema: TSchema,
  initialData?: Partial<z.infer<TSchema>>,
) {
  type FormData = z.infer<TSchema>;
  type FieldName = keyof FormData & string;
  type FormErrors = Partial<Record<FieldName | 'form', string>>;

  const [formData, setFormData] = useState<Partial<FormData>>(initialData || {});
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const validateField = (name: FieldName, value: FormFieldValue): void => {
    try {
      const fieldSchema = schema.shape[name];

      if (fieldSchema) {
        fieldSchema.parse(value);
        setErrors(prev => {
          const newErrors = { ...prev };

          delete newErrors[name];

          return newErrors;
        });
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const fieldError = error.errors[0];

        if (fieldError) {
          setErrors(prev => ({
            ...prev,
            [name]: fieldError.message,
          }));
        }
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void => {
    const { name, value, type } = e.target;
    const fieldName = name as FieldName;
    const input = e.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || 0;

    let formattedValue: FormFieldValue = value;

    if (type === 'number') {
      formattedValue = value === '' ? '' : Number(value);
    } else if (type === 'checkbox') {
      formattedValue = (e.target as HTMLInputElement).checked;
    } else if (name === 'phone') {
      formattedValue = formatPhoneNumber(value);
    } else if (name === 'offerPrice') {
      const previousValue = String(formData[fieldName] || '');

      formattedValue = formatCurrency(value);

      // Schedule a micro-task to restore cursor position
      setTimeout(() => {
        // If we added .00, don't move cursor if it was at the end
        const wasAtEnd = cursorPosition === previousValue.length;
        const formattedString = String(formattedValue);
        const shouldKeepCursor =
          wasAtEnd && formattedString.endsWith('.00') && !previousValue.endsWith('.00');

        input.setSelectionRange(
          shouldKeepCursor ? cursorPosition : cursorPosition,
          shouldKeepCursor ? cursorPosition : cursorPosition,
        );
      }, 0);
    }

    setFormData(prev => ({ ...prev, [fieldName]: formattedValue }));
    validateField(fieldName, formattedValue);
  };

  const handleSubmit = async (
    e: React.FormEvent,
    onSubmit: (data: FormData) => Promise<void>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    try {
      const validatedData = schema.parse(formData);

      await onSubmit(validatedData);
      setSubmitSuccess(true);
      setFormData({});
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: FormErrors = {};

        for (const err of error.errors) {
          const path = err.path[0];

          if (path && typeof path === 'string') {
            newErrors[path as FieldName] = err.message;
          }
        }

        setErrors(newErrors);
      } else {
        console.error('Form validation error:', error);
        setErrors({ form: 'An unexpected error occurred. Please try again.' } as FormErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    errors,
    isSubmitting,
    submitSuccess,
    handleChange,
    handleSubmit,
    resetForm: () => {
      setFormData({});
      setErrors({});
      setSubmitSuccess(false);
    },
  };
}

// Helper functions
const formatPhoneNumber = (value: string): string => {
  const numbers = value.replace(/\D/g, '');

  if (numbers.length <= 3) return numbers;
  if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;

  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
};

const formatCurrency = (value: string): string => {
  // If the user is trying to clear the input, let them
  if (!value || value === '0') {
    return '';
  }

  // Remove all non-numeric characters except decimal point
  const cleanValue = value.replace(/[^\d.]/g, '');

  // Ensure only one decimal point
  const parts = cleanValue.split('.');

  if (parts.length > 2) {
    parts.splice(2);
  }

  // Limit decimal places to 2
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2);
  }

  // Join back with single decimal point
  const numberStr = parts.join('.');

  // Only append .00 if there's a value and no decimal point
  if (numberStr && !numberStr.includes('.')) {
    return `${numberStr}.00`;
  }

  // If there's a decimal point but no or incomplete decimals, pad with zeros
  if (numberStr.endsWith('.')) {
    return `${numberStr}00`;
  }

  if (numberStr.match(/\.\d$/)) {
    return `${numberStr}0`;
  }

  return numberStr;
};
