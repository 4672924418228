'use client';

import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';

import useForm from '@/hooks/useForm';

import FormInput from './FormInput';
import SubmitButton from './SubmitButton';
import SuccessMessage from './SuccessMessage';

const FormSchema = z
  .object({
    name: z.string().min(3, 'Name must be at least 3 characters'),
    email: z.string().email('Invalid email address'),
    phone: z.string().regex(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
    offerPrice: z.string().refine(
      val => {
        const num = Number.parseFloat(val.replace(/[^0-9.]/g, ''));

        return !Number.isNaN(num) && num > 0 && num <= 100000;
      },
      { message: 'Offer must be between $0.01 and $100,000.00' },
    ),
  })
  .strip();

export function OfferFormClient() {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    offerPrice: '',
  };
  const { formData, errors, isSubmitting, handleChange, handleSubmit } = useForm(
    FormSchema,
    initialFormData,
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait for hydration to complete
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const onSubmit = async (): Promise<void> => {
    try {
      const response = await fetch('/api/offer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong, try again.');
      }

      setIsSubmitted(true);
    } catch (error) {
      console.error('Form submission error:', error);
      toast.error((error as Error).message || 'Something went wrong, try again.');
      throw error; // Re-throw to let useForm handle the error state
    }
  };

  return (
    <div className='mx-auto w-full max-w-md rounded-lg border border-white/10 bg-white/5 p-4 shadow-lg backdrop-blur-md'>
      {!isSubmitted ? (
        <>
          <h3 className='mb-2 text-center text-xl font-bold'>Make Your Offer Now!</h3>
          <form
            onSubmit={e => isHydrated && handleSubmit(e, onSubmit)}
            className={`space-y-4 transition-opacity duration-200 ${isHydrated ? 'opacity-100' : 'opacity-60'}`}
            suppressHydrationWarning={true}
          >
            <FormInput
              name='name'
              label='Name'
              icon='User'
              value={formData.name || ''}
              onChange={isHydrated ? handleChange : undefined}
              error={errors.name}
              placeholder='John Doe'
              disabled={!isHydrated}
            />
            <FormInput
              name='email'
              label='Email'
              icon='Mail'
              value={formData.email || ''}
              onChange={isHydrated ? handleChange : undefined}
              error={errors.email}
              placeholder='john@example.com'
              disabled={!isHydrated}
            />
            <FormInput
              name='phone'
              label='Phone Number'
              icon='Phone'
              value={formData.phone || ''}
              onChange={isHydrated ? handleChange : undefined}
              error={errors.phone}
              placeholder='(123) 456-7890'
              disabled={!isHydrated}
            />
            <FormInput
              name='offerPrice'
              label='Offer Price (USD)'
              icon='DollarSign'
              value={formData.offerPrice || ''}
              onChange={isHydrated ? handleChange : undefined}
              error={errors.offerPrice}
              placeholder='0.00'
              disabled={!isHydrated}
            />
            <SubmitButton
              isFormValid={!Object.keys(errors).length}
              isSubmitting={isSubmitting}
              disabled={!isHydrated}
            />
            {errors.form && <p className='mt-2 text-center text-sm text-red-500'>{errors.form}</p>}
          </form>
        </>
      ) : (
        <SuccessMessage />
      )}
      <p className='mt-4 text-center text-xs text-gray-400'>
        Your information is secure and private.
      </p>
      <p className='text-center text-xs text-gray-400'>
        We do not sell any of your personal information.
      </p>
    </div>
  );
}
