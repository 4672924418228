'use client';

import { motion } from 'framer-motion';

export default function SuccessMessage() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      className='mt-4 rounded-md bg-green-600 p-3 text-white'
    >
      Thank you for your offer! We&apos;ll be in touch soon.
    </motion.div>
  );
}
