'use client';

import { Lock } from 'lucide-react';

type SubmitButtonProps = {
  isFormValid: boolean;
  isSubmitting: boolean;
  disabled?: boolean;
};

export default function SubmitButton({ isFormValid, isSubmitting, disabled }: SubmitButtonProps) {
  return (
    <button
      type='submit'
      className={`flex w-full items-center justify-center rounded bg-blue-600 px-4 py-2 font-bold text-white transition duration-300 hover:bg-blue-700 ${
        !isFormValid || isSubmitting || disabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
      disabled={!isFormValid || isSubmitting || disabled}
    >
      {isSubmitting ? (
        <>
          <svg
            className='-ml-1 mr-3 h-5 w-5 animate-spin text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            role='img'
            aria-label='Loading'
          >
            <title>Loading</title>
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
          Submitting...
        </>
      ) : (
        <>
          <Lock size={18} className='mr-2' />
          Submit Offer
        </>
      )}
    </button>
  );
}
